


const Tokenomics = ()=>{

    return(           
        <div className=' flex flex-col items-center xl:w-[1300px] w-full m-auto py-[60px] xl:pt-[100px]  px-[15px]  xl:mt-[0px] xl:px-[0]' id="tokenomics">
            <h1 className=" font-bold text-[30px] md:text-[35px] max-w-[800px] text-center md:leading-[51px]">Additional Documents Needed to Buy a Property in the United States:</h1>
            <div className="  flex flex-col justify-start items-center ">
              <p 
                className="font-[700] md:leading-[51px] p-1 text-[14px] md:text-[16px] text-center">
                To purchase a property, non-citizens may need to submit some 
                 or all of the following documents to their mortgage lender:
                </p>
               <div className=" flex flex-col md:flex-row px-[25px] md:gap-16 mt-7">
                 <ul className="text-[#000]">
                    <li className="mb-4">ITIN</li>
                    <li className="mb-4">Proof of financial reserves</li>
                    <li className="mb-4">Tax return documents</li>
                    <li className="mb-4">Credit score information</li>

                 </ul>
                 <ul className="text-[#000]">
                    <li className="mb-4">Valid foreign passport, US visa, or driver's license</li>
                    <li className="mb-4">Bank statements and financial records from your home country</li>
                    <li className="mb-4">Pay stubs</li>
                    <li className="mb-4">Proof of Residence</li>

                 </ul>
               </div>

               <p 
                className="font-[700] leading-10 max-w-[900px] m-auto p-1 text-[14px] md:text-[18px] text-center">
                  Our mission at 80/20 Home Helpers is to assist individuals who are struggling to 
                  acquire homes due to inadequate documentation. We can buy the house on your 
                  behalf and offer it to you. If you have an ITIN, are prepared to buy, and meet 
                  the minimum requirements of a 20% cash down payment, proof of income, and the last 
                  two years of filed taxes, we are here to make your dream of homeownership come true.
                </p>
               
            </div>
            
        </div>
    )
}


export default Tokenomics;