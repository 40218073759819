
const Form = ()=>{
     

    return(
        <div className=" px-[25px] py-[70px] md:py-[70px]  flex flex-col items-center" id="Contact">
            <div className=" flex flex-col justify-between  md:gap-16 md:flex-row rounded-[48px] w-[100%]  h-[auto] md:max-w-[1300px] md:p-14  items-start ">
               <div className="flex flex-col items-start">
                <h2 className="text-[24px] mb-3 md:text-[36px] font-bold text-[#000] ">Fill out the Form or call us to get started</h2>
                <div className="border-[#ee6c4d] border-[1px] p-5 rounded-sm md:mt-10 !w-[95%]">
                <div className=" font-normal leading-8" >
                       <p className=" text-[14px] md:text-[20px]">Name:Isela Canela</p>
                      <a className="block text-[14px] md:text-[20px]" href="mailto:isela.canela@frontierstreet.us">Email: Isela.Canela@frontierstreet.us</a>
                      <a className="block text-[14px] md:text-[20px]" href="tel:317-983-1863">Phone: 317-983-1863</a>

                   </div>
                </div>
                <div className="border-[#ee6c4d] border-[1px] p-5 my-5 rounded-sm !w-[95%]">
                   <div className=" font-normal text-[14px] leading-8" >
                       <p className=" text-[14px] md:text-[20px]">Name: Jack Crenshaw</p>
                      <a className="block text-[14px] md:text-[20px]" href="mailto:jack.crenshaw@frontierstreet.us">  Email: Jack.crenshaw@frontierstreet.us</a>
                      <a className="block text-[14px] md:text-[20px]" href="tel:317-341-2474">Phone: 317-341-2474</a>

                   </div>
                </div>
               </div>
                <div className="w-full bg-[#24253a]">
                  <groovemail-form-embed permalink="NjUzNjg2NWMzNDE0NDQ1NWRiMWVlN2My" variation="64d17a21d710cc64bf490712"></groovemail-form-embed>
                </div>
            </div>
        </div>
    )
}

export default Form;