import Cash from '../../assets/img/cash.webp'
import Itn from '../../assets/img/itn.webp'
import Save from '../../assets/img/save.webp'



const Discover = () => {
	return (
		<div 
		   className=" py-[60px]  md:pt-[100px] md:pb-[123px] px-[15px]  md:mt-[0px] md:px-[0] z-30">
			<div 
				className="flex flex-col xl:max-w-[1240px] xl:m-[auto]"
			> 
			   <h1 className=" text-[24px] md:text-[45px] mb-5 font-semibold text-center"> 
			       How Can You Buy a House in the United States in 2023,
				     Even as an Immigrant?
				</h1>
				<div 
				 	className="md:flex items-start justify-between gap-5 flex-1  "
				>
					<div className="flex-1 flex flex-col py-[20px] shadow-md" >
						<img
						  src={Cash}
						  alt="cash"
						  className="h-[250px]"
						  
						/>
						<h2
						className="font-bold text-[21px] 
						 pt-[20px]
						 md:text-[30px] leading-[29px] md:leading-[32px] 
						 text-left  mb-[12px] md:mb-[0px] md:max-w-[400px] px-5 ">
							Buy It With Cash
						</h2>
						<p 
						className="text-[15px] md:text-[16px] font-[400] w-[100%] px-5 text-[#000] 
						md:w-[100%] text-left my-[0px] mb-[53px] md:mb-[0px md:my-[30px]  leading-[22px]">
							You can buy properties with cash, as there are no legal restrictions on this practice. Many foreign investors choose this path to secure homes in the country.

						</p>
					</div>
					<div className="flex-1 flex flex-col py-[20px] shadow-md" >
						<img
						  src={Itn}
						  alt="cash"
						  className="h-[250px]"
						/>
						<h2
						className="font-bold text-[21px] 
						 pt-[20px]
						 md:text-[30px] leading-[29px] md:leading-[32px] 
						 text-left  mb-[12px] md:mb-[0px] md:max-w-[400px] px-5 ">
							Apply for an ITIN Number

						</h2>
						<p 
						className="text-[15px] md:text-[16px] font-[400] w-[100%] px-5 text-[#000] 
						md:w-[100%] text-left my-[0px] mb-[53px] md:mb-[0px md:my-[30px]  leading-[22px]">
							Immigrants can also purchase a home with the help 
							of an Individual Taxpayer Identification Number (ITIN). An ITIN is 
							an alternative to a Social Security number and allows individuals with 
							businesses or property in the US to pay taxes on those assets.

						</p>
					</div>
					<div className="flex-1 flex flex-col py-[20px] shadow-md" >
						<img
						  src={Save}
						  alt="cash"
						  className="h-[250px]"
						  
						/>
						<h2
						className="font-bold text-[21px] 
						 pt-[20px]
						 md:text-[30px] leading-[29px] md:leading-[32px] 
						 text-left  mb-[12px] md:mb-[0px] md:max-w-[400px] px-5 ">
							Save for the Down Payment

						</h2>
						<p 
						className="text-[15px] md:text-[16px] font-[400] w-[100%] px-5 text-[#000] 
						md:w-[100%] text-left my-[0px] mb-[53px] md:mb-[0px md:my-[30px]  leading-[22px]">
							Once you obtain an ITIN, you can apply for a personal loan with the ITIN. 
							Numerous US companies and banks offer these services throughout the country 
							to help you achieve your homeownership goals.
						</p>
					</div>
					
				</div>
			</div>
		</div>
	);
};

export default Discover;
