
import React from 'react';
import { Heading, Paragraph } from "assets/styles/TextFace";

const Landing = () => {
  return (
    <>
    <div className='w-full py-[60px] text-[#fff]'
    style={{
			background: `url("/backlanding.webp")`,
			backgroundSize: 'contain',
		 }}
    >
      <div className='xl:w-[90%] m-auto  md:px-[35px] py-[60px] mt-[30px]  bg-[#24253a] bg-opacity-95'>
        <div className='flex  flex-col  md:gap-[0px] md:px-[50px]'>
          <div className=" flex flex-col items-start z-[3] max-w-[90%] m-auto ">
            <h3>Welcome to 80/20 Home Helpers</h3>
            <Heading>Making <span className="text-[#ffcc24]">Home ownership</span> a Reality for Everyone</Heading>
            <Paragraph>
               Are you an aspiring homeowner who lacks proper documentation to make the dream of 
               owning a house a reality? At 80/20 Home Helpers, we believe that the dream of 
               home ownership should be accessible to all, regardless of your immigration status. 
               We understand the challenges many people face in trying to purchase a home, and 
               we're here to help you navigate the process
             </Paragraph>
            <div className="flex justify-start mt-5  md:gap-7">
              <a href='#Contact'><button>  Get Started Today</button></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>

  );
};

export default Landing;

