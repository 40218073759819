import Navbar from "components/home/Navbar";
import PrivacyPage from "components/home/PrivacyPage";

  
  const PrivacyPolicy = () => (
    <main className=" flex flex-col items-center">
      <Navbar/>
      <PrivacyPage/>
    </main>
  );
  
  export default PrivacyPolicy;
  