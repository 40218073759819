import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import "./index.css"
import Footer from "components/home/Footer";
import PrivacyPolicy from "pages/PrivacyPolicy";
function App() {
  return (
    <Router>
       <Routes>
         <Route path="/" element={<Home />} />
         <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
       </Routes>
       <Footer/>
 </Router>
  );
}

export default App;
