
const Roadmap = () => {
   

    return (
        <div className='bg-[#24253a] text-[#fff] '>
        <div className='w-full xl:w-[1000px] flex flex-col items-center m-auto py-[60px]  xl:py-[100px] pb-[30px] px-[15px] mt-[0px] xl:mt-[0px] xl:px-[0]'>
            <h1 className="font-medium text-[25px]  md:text-[32px] text-center leading-[41px]  ">
               Enjoy Home Ownership in the United States.
            </h1>
            <p 
			  className="text-[15px] md:text-[16px] font-[400] w-[100%] px-5 text-[#fff] md:w-[100%] text-center  my-[20px]  md:my-[30px]  leading-[32px]">
				Contact 80/20 Home Helpers today, and let's work together to make 
                homeownership a reality for you!
			</p>
            <a href="#Contact"><button> Get Started Today</button></a> 
            
        </div>

        </div>
    );
};

export default Roadmap;
