import Logo from "../../assets/img/80-20-logo.png";



const Footer = () => {
	return (
		<div
			className="bg-[#24253a] px-[25px] pt-[40px] md:p-[50px] flex flex-col items-center relative"
			id="contact"
		>
			
			<div className="flex flex-col items-center justify-center">
				<img 
				 src={Logo}
				 alt="logo"
				 width={"200px"}
				 />
				<div className="mx-5 py-[13px] px-[10px] flex items-center justify-center">
					<p className="text-[#fff] text-center">
					  © <a href="https://www.frontierstreet.us" target="_blank" rel="noreferrer" >frontierstreet.us </a>All Rights Reserved.
					</p>
				</div>
			</div>
		</div>
	);
};

export default Footer;
