import Document from "../../assets/img/document.webp"

const Features = () => {
    return (
        <div className=" px-[15px] xl:px-[50px] flex flex-col items-center" id="Features">
            <div className=" bg-[#24253a]  flex flex-col md:flex-row  gap-5 max-w-[1272px]">
                 <img 
                   src={Document}
                   alt="document img"
                   className=" w-full h-[300px] md:m-10"
                 />

                 <div className="py-[50px] px-[20px] md:px-8">
                    <h2
				    className="font-bold text-[21px] text-[#fff]  pt-[20px] md:text-[30px] leading-[29px] md:leading-[32px]   mb-[12px] md:mb-[0px] md:max-w-[500px] px-5 ">
					  What Documents Do You Need to Buy a House?
                    </h2>
                    <p 
						className="text-[15px] md:text-[16px] font-[400] w-[100%] px-5 text-[#fff] 
						md:w-[100%]  my-[20px]   md:my-[30px]  leading-[22px]">
							Depending on your immigration status, 
                            you may require one of the following documents 
                            or migratory relief options:
					</p>
                    <ul className="text-[#fff] px-5">
                    <li className="mb-4">
                        <i className="fas fa-check-circle"></i> <strong>Green Card:</strong> If you have obtained a Green Card, you'll enjoy certain advantages similar to those of an American citizen, including a lower initial payment requirement.
                    </li>
                    <li className="mb-4">
                        <i className="fas fa-check-circle"></i> <strong>Valid Work Visa:</strong> Non-resident immigrants with employment visas may be eligible for mortgages. Some of these visas may provide access to loans from the Federal Housing Administration (FHA).
                    </li>
                    <li className="mb-4">
                        <i className="fas fa-check-circle"></i> <strong>Refugee or Asylee Status:</strong> Immigrants who have applied for asylum in the USA can also pursue mortgages. Essential documents include Form I-94 and an authorized employment document for at least 90 days from your entry into the country.
                    </li>
                    <li className="mb-4">
                        <i className="fas fa-check-circle"></i> <strong>Employment Contract:</strong> Workers whose contracts are due to renew within a year may need to provide a document from their company indicating the intent to renew the contract.
                    </li>
                    </ul>
                    <a href="#Contact"><button className="ml-5"> Get Started Today</button></a>
                    
                 </div>
            </div>
           


        </div>
    );
}


export default Features;