import {
  Features,
  Discover,
  Landing,
  Navbar,
  Tokenomics,
  NewsLetter,
  Roadmap,
} from "./index";

const Home = () => (
  <>
  
  <div id="google_translate_element" className="flex items-center justify-center"></div> 

  <main className=" flex flex-col items-center">

    {/* <div className=" w-full"> */}
      {/* <div className=" max-w-[1300px] m-auto"> */}
        <Navbar />
        <Landing />
      {/* </div> */}
    {/* </div> */}
    <div className="w-full">
      <Discover />
    </div>
    <div className="w-full">
      <Features />
    </div>
    <div className="w-full">
      <Tokenomics />
    </div>
    <div className="w-full">
      <Roadmap />
    </div>
    <div className="w-full">
      <NewsLetter />
    </div>
    
    
  </main>
  </>
);

export default Home;
