
import Logo from "../../assets/img/80-20-logo.png";



const DesktopNavbar = () => {
  return (
    <div className="flex justify-between py-[43px] px-[20px] md:px-[60px] items-center   ">
      <a href="/">
      <img alt="logo" className="inline w-[90px] md:w-[158px]" src={Logo} width={158.6} height={53.95}/>
      </a>

      <div className="flex justify-center items-center gap-8 ">
      <a href="/#Contact">
       <button className="!p-[15px] md:p-[20px]"> Get Started Today</button>

      </a>
      </div>
    </div>
  );
};


const Navbar = () => {

  return (
      <div
        className="w-full  bg-[#24253a] z-20 "
      >
          <DesktopNavbar />
          {/* <MobileNav /> */}
      </div>
  );
};

export default Navbar;