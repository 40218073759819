import React from 'react'

export const Heading = ({children}) => {
  return (
    <h1 className='  text-[25px] md:text-[64px] mb-4 font-[700] md:leading-[72px]'>
         {children} 
    </h1>
  )
}

export const Heading2 = ({children}) => {
  return (
    <h2 className="font-bold text-[21px] border-gradient md:text-[40px] leading-[29px] md:leading-[52px] 
            text-left md:mt-[20px] mb-[32px] md:mb-[0px]">
							Discover, partake and 
							<span className="gradient-text"> earn</span>
		</h2>
  )
}

export const Paragraph = ({children}) => {
    return (
      <p className= 'text-[16px]  font-normal max-w-[600px] md:w-[80%]  mb-4  leading-[20px] md:leading-[30px]'>
           {children} 
      </p>
    )
  }

